import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Form, { FormContext, Input, Submit } from 'components/Forms'

import validator from 'validator'

import './teammembers.scss'
import { useMutation } from '@apollo/client'
import { LINK_SALES_REP_ACCOUNT, ME } from 'operations/Auth'
import { useHistory, Link } from 'react-router-dom'
import Card from 'components/Card'
import { useCompany } from 'hooks/company'

const VALID_DOMAINS = {
	NAPA: [
		'@napacanada.com',
		'@uapinc.com',
		'@altrom.com',
		'@autocamping.ca',
		'@idealsupply.com',
		'@alaincote.ca',
		'@bartonauto.com',
		'@brittonautomotive.com',
		'@paggm.com',
		'@paramountparts.ca',
		'@universalgrp.ca',
		'@automoteur.ca',
		'@midwaydistributors.com',
		'@napamiramichi.com',
		'@pgrpc.com',
		'@accro.ca',
		'@palacroix.com',
	],
	AAG: ['@altrom.com', '@autocamping.ca', '@napacanada.com', '@uapinc.com'],
}

export default function TeamMembers() {
	const company = useCompany()
	const history = useHistory()

	const { t } = useTranslation()
	const [errors, setErrors] = useState({})

	const [linkSalesRepAccount] = useMutation(LINK_SALES_REP_ACCOUNT, {
		refetchQueries: [{ query: ME }],
	})

	const ERRORS = {
		email: 'Please enter a valid email address.',
		invalidDomain: t(
			'The email you entered is not from an accepted email domain.',
		),
	}

	return (
		<div className="contentContainer unauthorizedContainer">
			<h1>{t('teamMembersHeading', 'Team Members')}</h1>

			<Form
				onSubmit={async ({ inputs, enableSubmit }) => {
					const formErrors = {}

					if (
						validator.isEmpty(inputs.email) ||
						!validator.isEmail(inputs.email)
					)
						formErrors.email = true

					if (
						!VALID_DOMAINS[company].includes(
							`@${inputs.email.split('@')[1]}`,
						)
					)
						formErrors.invalidDomain = true

					if (Object.keys(formErrors).length > 0) {
						enableSubmit()
						return setErrors(formErrors)
					}

					try {
						await linkSalesRepAccount({
							variables: {
								payload: {
									username: inputs.email,
								},
							},
						})

						history.push('/settings/team-members')
					} catch (err) {
						console.log(err)
						enableSubmit()
					}
				}}
			>
				<FormContext.Consumer>
					{([{ inputs }, setFormValue]) => {
						return (
							<>
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: 'auto 1fr',
										gap: '4rem',
									}}
								>
									<Card style={{ display: 'block' }}>
										<h2>{t('Accepted email domains')}</h2>
										<ul>
											{VALID_DOMAINS[company].map(
												(validDomain, i) => (
													<li key={i}>
														{validDomain}
													</li>
												),
											)}
										</ul>
									</Card>
									<div>
										<h2>
											{t(
												'addTeamMemberSubheading',
												'Add a team member',
											)}
										</h2>
										{Object.keys(errors).length > 0 && (
											<ul className="errorContainer">
												{Object.keys(errors).map(
													key => (
														<li key={key}>
															{ERRORS[key]}
														</li>
													),
												)}
											</ul>
										)}
										<Input
											name="email"
											label={t(
												'addTeamMemberFormLabelEmail',
												'Email address',
											)}
										/>

										<p>
											{t(
												'teamMemberSalesRepHelper',
												'Team members or sales reps can enroll customers and submit warranty claims on your behalf.',
											)}
										</p>

										<div
											style={{
												display: 'flex',
												gap: '1rem',
												alignItems: 'center',
											}}
										>
											<Submit context="no-margin">
												{t(
													'btnSubmitAddTeamMember',
													'Add team member',
												)}
											</Submit>{' '}
											or{' '}
											<Link to="/settings/team-members">
												{t('go back')}
											</Link>
										</div>
									</div>
								</div>
							</>
						)
					}}
				</FormContext.Consumer>
			</Form>
		</div>
	)
}
