import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Form, { Input, Submit } from '../../components/Forms'
import { FORGOT_PASSWORD } from '../../operations/Auth'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../components/ErrorMessage'

export default function Login({ history }) {
	const { t, i18n } = useTranslation()

	const ERROR_MESSAGES = {
		USER_NOT_FOUND: t(
			'loginErrorUserNotFound',
			'Sorry, but we were unable to find an account with that username.',
		),
		USER_INACTIVE: t(
			'loginErrorUserInactive',
			'Sorry, but your account has been deactivated.',
		),
	}

	const [errorMessage, setErrorMessage] = useState('')
	const [resetEmail, setResetEmail] = useState()

	const [forgotPassword] = useMutation(FORGOT_PASSWORD)

	if (resetEmail)
		return (
			<div style={{ fontSize: '1.6rem' }}>
				{t(
					'passwordResetConfirmation',
					'We just sent a password reset to {{ resetEmail }}. Please check your inbox for instructions on resetting your password.',
					{ resetEmail },
				)}
			</div>
		)

	return (
		<Form
			onSubmit={({ inputs: { username }, resetForm, enableSubmit }) => {
				forgotPassword({
					variables: {
						payload: {
							username,
						},
					},
				})
					.then(({ data: { forgotPassword } }) => {
						setResetEmail(forgotPassword)
					})
					.catch(err => {
						const { graphQLErrors } = err
						enableSubmit()
						setErrorMessage(
							ERROR_MESSAGES[graphQLErrors[0].message],
						)
					})
			}}
		>
			<div className="contentContainer contentContainer--login">
				<h1>
					{t('forgotYourPassword', 'Forgot your password?')}
					<button
						type="button"
						className="languageButton"
						onClick={() =>
							i18n.changeLanguage(
								i18n.language === 'en' ? 'fr' : 'en',
							)
						}
					>
						{i18n.language === 'fr' ? 'English' : 'Français'}
					</button>
				</h1>

				{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

				<Input label={t('username', 'Username')} name="username" />
				<Submit fullWidth>
					{t(
						'sendMeAPasswordReset',
						'Send Me a Password Reset Email',
					)}
				</Submit>
			</div>
		</Form>
	)
}
