import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import { ApolloProvider } from '@apollo/client'
import client from './Apollo'

import AuthenticationProvider from './hooks/useAuthentication'
import Routes from './Routes'

import Loader from './components/Loader'
import HealthCheck from './components/HealthCheck'
import RefreshProvider from './hooks/useRefresh'

import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'

import './i18n'
import CompanyProvider from 'hooks/company'

Sentry.init({
	dsn: 'https://b2270ca4c15448c0b3f7cdfe5f0dcad4@o126375.ingest.sentry.io/5285708',
	environment: process.env.NODE_ENV,
})

ReactDOM.render(
	<React.StrictMode>
		<CompanyProvider>
			<RefreshProvider>
				<ApolloProvider client={client}>
					<AuthenticationProvider>
						<Suspense fallback={<Loader />}>
							<HealthCheck />
							<Routes />
						</Suspense>
					</AuthenticationProvider>
				</ApolloProvider>
			</RefreshProvider>
		</CompanyProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
