import React from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	//   Link,
} from 'react-router-dom'

import GAListener from './GAListener'

import Unauthorized from './components/Unauthorized'
// import Nav from './components/Nav'
// import Breadcrumbs from './components/Breadcrumbs'
// import './styles/default.css';
import './styles/default.scss'
// import Footer from './components/Footer'
// import NPS from './components/NPS'
// import Document from './components/Document'

import { useAuthentication } from './hooks/useAuthentication'
// import { useGlobalState } from './hooks/useGlobalState'

import Header from 'components/Header'

import Login from './pages/Login'
import Forgot from './pages/Forgot'
// import Claims from './pages/Claims'
// import SubmitClaim from './pages/SubmitClaim'
// import Claim from './pages/Claim'
// import Installers from './pages/Installers'
// import Stores from './pages/Stores'
// import Bds from './pages/Bds'
// import Account from './pages/Account'
import Activate from './pages/Activate'
// import Resources from './pages/Resources'
// import HeadOffice from './pages/HeadOffice'

// import PageNotFound from './pages/PageNotFound'

import ScrollRestoration from './ScrollRestoration'
import Refresh from './components/Refresh'

import Membership from './pages/Membership'
import TeamMembers from './pages/Settings/TeamMembers'
import AddTeamMember from './pages/Settings/TeamMembers/AddTeamMember'
import AddSalesRep from 'pages/Settings/TeamMembers/AddSalesRep'

import Debug from './pages/Debug'

import OAUTH from './pages/OAUTH'
import { useCompany } from 'hooks/company'
import Shield from 'components/Shield'
// import { useCompany } from 'hooks/company';

// import { useTranslation } from 'react-i18next';

function Layout({ location }) {
	// const company = useCompany()

	return (
		<Unauthorized>
			<Switch>
				<Route path="/oauth/:language/:token" component={OAUTH} />
				<Route
					path="/a/:language/:program/:token"
					component={Activate}
				/>
				<Route
					path="/r/:language/:program/:token"
					component={Activate}
				/>
				<Route path="/r/:language/:token" component={Activate} />
				<Route path="/forgot" component={Forgot} />
				<Route path="/l/:language/:program" component={Login} />
				<Route path="/debug" component={Debug} />
				<Route path="/" exact component={Login} />
			</Switch>
		</Unauthorized>
	)
}

function AuthorizedLayout({ history }) {
	const { user } = useAuthentication()
	return (
		<>
			<ScrollRestoration />
			<div className="wrapper">
				<Header />
				<Switch>
					<Route
						exact
						path="/settings/team-members"
						component={TeamMembers}
					/>
					<Route
						path="/settings/team-members/add-team-member"
						component={
							user.userType === 'STORE'
								? AddSalesRep
								: AddTeamMember
						}
					/>
					<Route path="/oauth/:language/:token" component={OAUTH} />
					<Route
						path="/a/:language/:program/:token"
						component={Activate}
					/>
					<Route
						path="/l/:language/:program"
						render={() => {
							history.push('/')
						}}
					/>
					<Route path="/debug" component={Debug} />
					<Route path="/" exact component={Membership} />
				</Switch>
			</div>
		</>
	)
	// return (
	//     <>
	//         <ScrollRestoration />
	// 		<header className="header">
	// 			<div className='logo'>
	//                 <Link to='/'><img src={ i18n.language === 'fr' ? logoFr : logoEn } alt='NAPA Members' /></Link>
	//             </div>
	// 			<Nav />
	// 		</header>
	// 		<div className="main">

	//             <Breadcrumbs />

	//             <Switch>
	//                 <Route path='/i/:invoice' component={ Document } />
	//                 <Route path='/submit-claim/:claimKey?' component={ SubmitClaim } />
	//                 <Route path='/claim/:claimKey' component={ Claim } />
	//                 <Route path='/account' component={ Account } />
	//                 <Route path='/resources' component={ Resources } />
	//                 <Route path='/' component={ RootComponent } />
	//                 <Route component={ PageNotFound } />
	//             </Switch>
	//         </div>
	// 		<Footer />
	//         <NPS />
	//     </>
	// )
}

function Routes() {
	const company = useCompany()
	const { isAuthenticated } = useAuthentication()

	return (
		<>
			<div
				className={`background background--${
					isAuthenticated ? 'authorized' : 'unauthorized'
				}`}
			>
				{company === 'NAPA' && <Shield />}
			</div>
			<Router>
				<Route
					component={isAuthenticated ? AuthorizedLayout : Layout}
				/>
				<Route component={GAListener} />
				<Route component={Refresh} />
			</Router>
		</>
	)

	// if (!isAuthenticated) {
	//     return (
	//         <div className='background background--unauthorized'>
	//             <Router>
	//                 <Route component={ Layout } />
	//                 <Route component={ GAListener } />
	//                 <Route component={ Refresh } />
	//             </Router>
	//         </div>
	//     )
	// }

	// return(
	//     <div className='background background--authorized'>
	//         <Router>
	//             <Route component={ AuthorizedLayout } />
	//             <Route component={ GAListener } />
	//             <Route component={ Refresh } />
	//         </Router>
	//     </div>
	// )
}

export default Routes
