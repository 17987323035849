import React from 'react'
import { useRefresh } from '../../hooks/useRefresh'
import './refresh.scss'

export default function Refresh({ location }) {

    const { shouldRefresh } = useRefresh()

    if (shouldRefresh && location.pathname.indexOf("/submit-claim/") === -1) return (
        <div className='refreshContainer'>
            New features are available. Refresh the page to see what's new: <button type='button' onClick={() => window.location.reload(true)}>Click to Refresh</button>
        </div>
    )

    return null
}