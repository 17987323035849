import React from 'react'

import './nodata.scss'

export default function NoData({ children }) {
    return (
        <div className='noData__container'>
            <div className='noData'>
                { children }
            </div>
        </div>
    )
}