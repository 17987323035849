import React, { useState, useEffect } from 'react'
import InputWrapper from './InputWrapper'
import { useForm } from './useForm'
import { Icon } from '@iconify/react'
// import accents from 'remove-accents'

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Input({
	label,
	name,
	value = '',
	type = 'text',
	id,
	min,
	max,
	maxLength,
	required = false,
	onChange,
	placeholder,
	regex,
	autoComplete = 'on',
	formatting = [],
}) {
	// const [stateValue, setValue] = useState(value);
	const [isFocused, setFocus] = useState(false)
	const [eyeballVisibility, setEyeballVisibility] = useState(false)

	// Use global form state
	const [{ inputs }, setFormValue] = useForm()

	// On init, set object name
	useEffect(() => {
		if (!inputs[name] || (id && !inputs[name][id])) {
			setFormValue(
				{
					[name]: value,
				},
				id,
			)
		}
		// eslint-disable-next-line
	}, [])

	const inputId = `f_${name}${id > -1 ? `_${id}` : ''}`

	if (autoComplete === 'on') {
		if (name === 'username') {
			autoComplete = 'username'
		}
		if (type === 'password') {
			autoComplete = 'current-password'
		}
	}

	return (
		<InputWrapper
			label={label}
			inputId={inputId}
			isFocused={isFocused}
			type={type}
			isFilled={
				(id > -1 && inputs[name] && inputs[name][id]) ||
				(!id && id !== 0 && inputs[name])
					? true
					: false
			}
			eyeballVisibility={eyeballVisibility}
			onEyeballClick={() => setEyeballVisibility(!eyeballVisibility)}
		>
			<input
				id={inputId}
				type={eyeballVisibility ? 'text' : type}
				name={name}
				min={type === 'number' ? min : null}
				max={type === 'number' ? max : null}
				required={required}
				value={
					id > -1 && inputs[name]
						? inputs[name][id] || ''
						: inputs[name] || ''
				}
				onChange={e => {
					let formattedValue = regex
						? e.target.value.replace(regex[0], regex[1])
						: e.target.value

					formatting.map(format => {
						if (format === 'uppercase')
							return (formattedValue =
								formattedValue.toUpperCase())
						return null
					})

					if (maxLength)
						formattedValue = formattedValue.substr(0, maxLength)

					setFormValue(
						{
							[name]: formattedValue,
						},
						id,
					)
					// Allow listening to onChange for this specific input
					if (onChange)
						onChange({
							target: {
								name: e.target.name,
								value: formattedValue,
							},
						})
				}}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				autoComplete={autoComplete}
				placeholder={placeholder}
			/>

			{required ? <span className="required-hint"></span> : null}
		</InputWrapper>
	)
}
