import React from 'react'
import moment from 'moment'

export default function Debug() {

   return (
        <div className='contentContainer'>
            <h1>Debugging</h1>
            {
                JSON.stringify(moment())
            }
        </div>
   )

}