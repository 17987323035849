import React from 'react'

import './card.scss'

export default function Card({ children, style }) {
	return (
		<div className="card" style={style}>
			{children}
		</div>
	)
}

export function CardTitle({ title, children, style }) {
	return (
		<div className="cardTitle__container">
			<div className="cardTitle">{title}</div>
			<div style={style}>{children}</div>
		</div>
	)
}
