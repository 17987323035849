import React from 'react'
import { useTranslation } from 'react-i18next'
import NoData from '../../../components/NoData'

import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
	DELETE_EMPLOYEE_ACCOUNT,
	REMOVE_SALES_REP_PERMISSIONS,
	ME,
} from 'operations/Auth'
import Loader from 'components/Loader'
import Card, { CardTitle } from 'components/Card'
import { useAuthentication } from 'hooks/useAuthentication'
import Form, { FormContext } from 'components/Forms'

export default function TeamMembers() {
	const { t } = useTranslation()

	const { user } = useAuthentication()

	const { error, loading, data } = useQuery(ME)

	const [deleteLinkedEmployeeAccount] = useMutation(DELETE_EMPLOYEE_ACCOUNT, {
		refetchQueries: [{ query: ME }],
	})

	const [removeSalesRepPermissions] = useMutation(
		REMOVE_SALES_REP_PERMISSIONS,
		{
			refetchQueries: [{ query: ME }],
		},
	)

	if (error) return 'Error: ' + error.message
	if (loading) return <Loader />

	return (
		<div className="contentContainer unauthorizedContainer">
			<h1>
				{user.userType === 'STORE'
					? t(
							'teamMembersHeading--store',
							'Team Members / Sales Reps',
					  )
					: t('teamMembersHeading', 'Team Members')}
			</h1>

			{data &&
			data.me &&
			data.me.userPermissionGrants &&
			data.me.userPermissionGrants.length > 0 ? (
				<>
					{data.me.userPermissionGrants.map(grantee => (
						<Card key={grantee.id}>
							<CardTitle title="Username">
								{grantee.username}
							</CardTitle>
							<CardTitle title="Program access">
								{grantee.scopes
									?.filter(granteeScope =>
										data.me.scopes.includes(granteeScope),
									)
									.join(' / ')}
							</CardTitle>
							<CardTitle
								title=""
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'end',
									gap: '1rem',
								}}
							>
								{user.userType === 'INSTALLER' && (
									<Link
										to={`/settings/team-members/add-team-member?u=${encodeURIComponent(
											grantee.username,
										)}&s=${encodeURIComponent(
											grantee.scopes,
										)}`}
										className="modifyButton"
									>
										<i className="material-icons">edit</i>
									</Link>
								)}
								<button
									type="button"
									className="modifyButton"
									onClick={() => {
										console.log({ user })
										if (user.userType === 'STORE') {
											removeSalesRepPermissions({
												variables: {
													payload: {
														salesRepId: grantee.id,
													},
												},
											})
										}

										if (user.userType === 'INSTALLER') {
											deleteLinkedEmployeeAccount({
												variables: {
													payload: {
														username:
															grantee.username,
													},
												},
											})
										}
									}}
								>
									<i className="material-icons">delete</i>
								</button>
							</CardTitle>
						</Card>
					))}
					<Link
						className="nodataButton"
						to="/settings/team-members/add-team-member"
					>
						{t('btnAddTeamMember', 'Add a team member')}
					</Link>
				</>
			) : (
				<NoData>
					<p>
						{t(
							'teamMembersNoneExisting',
							"Looks like you haven't added any team members yet.",
						)}
					</p>
					{user.userType === 'STORE' && (
						<p>
							{t(
								'teamMemberSalesRepHelper',
								'Team members or sales reps can enroll customers and submit warranty claims on your behalf.',
							)}
						</p>
					)}
					<Link
						className="nodataButton"
						to="/settings/team-members/add-team-member"
					>
						{t('btnAddTeamMember', 'Add a team member')}
					</Link>
				</NoData>
			)}
		</div>
	)
}
