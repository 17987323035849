import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Form, { FormContext, Input, Submit } from 'components/Forms'

import validator from 'validator'

import './teammembers.scss'
import { useMutation, useQuery } from '@apollo/client'
import { LINK_EMPLOYEE_ACCOUNT, ME } from 'operations/Auth'
import Loader from 'components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'

const ERRORS = {
	email: 'Please enter a valid email address.',
	scopes: 'Please select a program to grant access to.',
}

export default function TeamMembers() {
	const history = useHistory()
	const location = useLocation()

	const { t } = useTranslation()
	const [errors, setErrors] = useState({})

	const { error, loading, data } = useQuery(ME)

	const [linkEmployeeAccount] = useMutation(LINK_EMPLOYEE_ACCOUNT, {
		refetchQueries: [{ query: ME }],
	})

	if (error) return 'Error: ' + error.message
	if (loading) return <Loader />

	const searchParams = new URLSearchParams(location?.search)
	const username = searchParams?.get('u')
	const scopes = searchParams?.get('s')

	const initScopes = {}
	scopes &&
		scopes
			.split(',')
			.map(scope => (initScopes[`scope.${scope.toLowerCase()}`] = true))

	return (
		<div className="contentContainer unauthorizedContainer">
			<h1>{t('teamMembersHeading', 'Team Members')}</h1>

			<h2>
				{username
					? t('updateTeamMemberSubheading', 'Update team member')
					: t('addTeamMemberSubheading', 'Add a team member')}
			</h2>

			<Form
				init={{ email: username || '', ...initScopes }}
				onSubmit={async ({ inputs, enableSubmit }) => {
					const scopes = []
					Object.keys(inputs).map(key => {
						const fields = key.split('.')
						if (fields[0] === 'scope' && inputs[key]) {
							return scopes.push(fields[1].toUpperCase())
						}
						return null
					})

					const formErrors = {}

					if (
						validator.isEmpty(inputs.email) ||
						!validator.isEmail(inputs.email)
					)
						formErrors.email = true
					if (scopes.length === 0) formErrors.scopes = true

					if (Object.keys(formErrors).length > 0) {
						enableSubmit()
						return setErrors(formErrors)
					}

					try {
						await linkEmployeeAccount({
							variables: {
								payload: {
									username: inputs.email,
									scopes,
								},
							},
						})

						history.push('/settings/team-members')
					} catch (err) {
						console.log(err)
						enableSubmit()
					}
				}}
			>
				<FormContext.Consumer>
					{([{ inputs }, setFormValue]) => {
						return (
							<>
								{Object.keys(errors).length > 0 && (
									<ul className="errorContainer">
										{Object.keys(errors).map(key => (
											<li key={key}>{ERRORS[key]}</li>
										))}
									</ul>
								)}
								<Input
									name="email"
									label={t(
										'addTeamMemberFormLabelEmail',
										'Email address',
									)}
								/>

								{/* Make employee account available scopes dependent on main installer account scopes */}
								{data &&
									data.me &&
									data.me.scopes &&
									data.me.scopes.length > 0 &&
									data.me.scopes.map(program => {
										if (program === 'WARRANTY') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														type="checkbox"
														name="scope.warranty"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.warranty'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMemberLWAccess',
															'Grant access to Labour Warranty.',
														)}
													</span>
												</label>
											)
										}

										if (program === 'PROMOTIONS') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														name="scope.promotions"
														type="checkbox"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.promotions'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMemberPromosAccess',
															'Grant access to Promotions.',
														)}
													</span>
												</label>
											)
										}

										if (program === 'REBATES') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														name="scope.rebates"
														type="checkbox"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.rebates'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMemberRebatesAccess',
															'Grant access to Consumer Rebates.',
														)}
													</span>
												</label>
											)
										}

										if (program === '10_400_WARRANTY') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														name="scope.10_400_warranty"
														type="checkbox"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.10_400_warranty'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMember10400Access',
															'Grant access to 10/400 Warranty.',
														)}
													</span>
												</label>
											)
										}

										if (program === 'ROADSIDE') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														name="scope.roadside"
														type="checkbox"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.roadside'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMemberRoadsideAccess',
															'Grant access to NAPA Roadside Assistance.',
														)}
													</span>
												</label>
											)
										}

										if (program === 'COOP') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														name="scope.coop"
														type="checkbox"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.coop'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMemberCoopAccess',
															'Grant access to NAPA COOP.',
														)}
													</span>
												</label>
											)
										}
										if (program === 'BACKEND') {
											return (
												<label
													key={program}
													className="checkbox"
												>
													<input
														name="scope.backend"
														type="checkbox"
														onChange={({
															target: {
																name,
																checked,
															},
														}) =>
															setFormValue({
																[name]: checked,
															})
														}
														checked={Boolean(
															inputs[
																'scope.backend'
															],
														)}
													/>
													<span>
														{t(
															'addTeamMemberBackendAccess',
															'Grant access to NAPA BACKEND.',
														)}
													</span>
												</label>
											)
										}
										return null
									})}
								<Submit>
									{username
										? t(
												'updateTeamMemberSubheading',
												'Update team member',
										  )
										: t(
												'btnSubmitAddTeamMember',
												'Add team member',
										  )}
								</Submit>
							</>
						)
					}}
				</FormContext.Consumer>
			</Form>
		</div>
	)
}
