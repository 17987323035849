import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import Form, { Input, Submit } from '../../components/Forms'
import { useAuthentication } from '../../hooks/useAuthentication'
import { AUTHENTICATE } from '../../operations/Auth'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../components/ErrorMessage'
import { Link } from 'react-router-dom'

export default function Login({ match }) {
	const { t, i18n } = useTranslation()

	const { language, program } = match.params

	// const client = useApolloClient()

	const [authenticate] = useMutation(AUTHENTICATE)

	useEffect(() => {
		if (i18n.language !== language) i18n.changeLanguage(language)
	}, [i18n, language])

	const ERROR_MESSAGES = {
		USER_NOT_FOUND: t(
			'loginErrorUserNotFound',
			'Sorry, but we were unable to find an account with that username.',
		),
		BAD_PASSWORD_MATCH: t(
			'loginErrorBadPasswordMatch',
			'Sorry, but your password is incorrect.',
		),
		USER_INACTIVE: t(
			'loginErrorUserInactive',
			'Sorry, but your account has been deactivated.',
		),
		USER_INVALID_EMAIL: t(
			'loginErrorUserInvalidEmail',
			'Sorry, but your email has not been verified. Please check your inbox and spam/junk folders for an account activation email from accounts@mail.napalabourwarranty.com',
		),
	}

	const { setAuthenticated } = useAuthentication()
	const [errorMessage, setErrorMessage] = useState('')

	return (
		<Form
			onSubmit={({
				inputs: { username, password },
				resetForm,
				enableSubmit,
			}) => {
				authenticate({
					variables: {
						payload: {
							username,
							password,
							...(program && { client: program }),
						},
					},
				})
					.then(async ({ data: { authenticate } }) => {
						localStorage.setItem(
							'accessToken',
							authenticate.accessToken,
						)
						localStorage.setItem(
							'refreshToken',
							authenticate.refreshToken,
						)
						setAuthenticated(true)
						if (program) {
							const { oauthToken, oauthRedirect } = authenticate
							const uri = `${oauthRedirect}/oauth/${i18n.language}/${oauthToken}`
							window.location.replace(uri)
						}
					})
					.catch(err => {
						const { graphQLErrors } = err
						enableSubmit()
						setErrorMessage(
							ERROR_MESSAGES[graphQLErrors[0].message],
						)
					})
			}}
		>
			<div className="contentContainer contentContainer--login">
				<h1>
					{t('login', 'Login')}
					<button
						type="button"
						className="languageButton"
						onClick={() =>
							i18n.changeLanguage(
								i18n.language === 'en' ? 'fr' : 'en',
							)
						}
					>
						{i18n.language === 'fr' ? 'English' : 'Français'}
					</button>
				</h1>

				{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

				<Input label={t('username', 'Username')} name="username" />
				<Input
					label={t('password', 'Password')}
					type="password"
					name="password"
				/>
				<Link className="forgotPasswordLink" to="/forgot">
					{t('forgotPasswordLink', 'Forgot your password?')}
				</Link>
				<Submit fullWidth>{t('login', 'Login')}</Submit>
			</div>
		</Form>
	)
}
