import React, { createContext, useContext, useState, useEffect } from 'react'

const FormContext = createContext()

export default function Form({ children, onChange, onSubmit, init = {} }) {
    const [state, setState] = useState({
        submitted: false,
        inputs: init
    })

    useEffect(() => {
        if (onChange) {
            onChange(state)
        }
    })

    function handleSetState(input, id) {
        // let modifiedInput = {}
        if (id > -1) {
            setState(state => ({
                ...state,
                inputs: {
                    ...state.inputs,
                    [Object.keys(input)[0]]: {...state.inputs[Object.keys(input)[0]], [id]: input[Object.keys(input)[0]]}
                }
            }))
        }
        else {
            setState(state => ({
                ...state,
                inputs: { ...state.inputs, ...input }
            }))
        }
    }

    function handleFormReset() {
        // const { inputs } = state
        // Object.keys(inputs).map((input) => {
        //     return inputs[input] = ''
        // })
        setState(state => ({
            ...state,
            inputs: {}
        }))
    }

    function handleEnableSubmit() {
        setState(state => ({
            ...state,
            submitted: false
        }))
    }

    return(
        <FormContext.Provider
            value={[
                state,
                (input, id) => handleSetState(input, id),
            ]}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    setState(state => ({
                        ...state,
                        submitted: true
                    }))

                    if (onSubmit) {
                        onSubmit({inputs: state.inputs, resetForm: handleFormReset, enableSubmit: handleEnableSubmit})
                    }
                }}
            >
                { children }
            </form>
        </FormContext.Provider>
    )

}

function useForm() {
    return useContext(FormContext)
}

export {
    useForm,
    FormContext
}