import React from 'react'

import { useAuthentication } from 'hooks/useAuthentication'
import { useApolloClient } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import napaLogoEn from '../../assets/napaProgramsLogo_en.svg'
import napaLogoFr from '../../assets/napaProgramsLogo_fr.svg'
import altromLogo from '../../assets/altromLogo.svg'
import { useCompany } from '../../hooks/company'

import './header.scss'
import { NavLink, useHistory } from 'react-router-dom'

export default function Header() {
	const { t, i18n } = useTranslation()
	const { setAuthenticated, user } = useAuthentication()
	const client = useApolloClient()
	const history = useHistory()
	const company = useCompany()

	const logos = {
		NAPA: {
			en: napaLogoEn,
			fr: napaLogoFr,
		},
		AAG: {
			en: altromLogo,
			fr: altromLogo,
		},
	}

	const logo = logos[company][i18n.language]

	return (
		<div className="header__container">
			<nav>
				<div className="nav--left">
					{['INSTALLER', 'STORE'].includes(user.userType) && (
						<>
							<NavLink exact to="/">
								<i className="material-icons">speed</i>
								{t('myProgramsNavDashboardLink', 'Dashboard')}
							</NavLink>
							<NavLink to="/settings/team-members">
								<i className="material-icons">people</i>
								{t(
									'myProgramsNavTeamMembersLink',
									'Team Members',
								)}
							</NavLink>
						</>
					)}
				</div>
				<div className='nav-center'>
                {['INSTALLER','EMPLOYEE'].includes(user.userType) && (
					<a target="_blank" rel="noopener noreferrer" href="https://www.napacanadaportal.com"><i className='material-icons'>language</i>{t('myProgramsNavNapaCanadaPortal', 'NAPA Canada Portal')}</a>
				)}
				</div>
				<div className="nav-right">
					<button
						type="button"
						className="logoutButton"
						onClick={() => {
							// setExpanded(false)
							// resetGlobalState()
							console.log('logout button line 36')

							localStorage.removeItem('accessToken')
							localStorage.removeItem('refreshToken')
							setAuthenticated(false)
							client.clearStore()
							history.replace('/')
						}}
					>
						{t('logout', 'Logout')}
						<i className="material-icons">exit_to_app</i>
					</button>
				</div>
			</nav>

			<header>
				<div className="logo">
					<img
						src={logo}
						alt={t('napaProgramsHeading', 'My Programs')}
					/>
				</div>
			</header>
		</div>
	)
}
