import gql from 'graphql-tag'

export const GET_INSTALLER = gql`
	query Installer($filter: InstallerFilter, $allowCancelled: Boolean) {
		installer(filter: $filter, allowCancelled: $allowCancelled) {
			id
			navisionId
			ownerName
			businessName
			legalName
			address
			city
			province
		}
	}
`
