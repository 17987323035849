import React from 'react'
import {
	useQuery,
	useMutation,
	// useApolloClient
} from '@apollo/client'
import { ME, OAUTH_GENERATE } from '../../operations/Auth'

import { useAuthentication } from '../../hooks/useAuthentication'

import './membership.scss'
// import logoEn from '../../assets/logoOnly_en.png'
import { useTranslation } from 'react-i18next'
import { GET_STORE } from '../../operations/Store'
import { GET_INSTALLER } from '../../operations/Installer'
import Loader from '../../components/Loader'
import NoData from '../../components/NoData'

export default function Membership({ history }) {
	const { t, i18n } = useTranslation()

	const { user } = useAuthentication()

	const {
		error: queryError,
		loading: queryLoading,
		data: queryData,
	} = useQuery(ME)
	const [oauthGenerate] = useMutation(OAUTH_GENERATE)

	if (queryError || queryLoading) return null

	const PROGRAMS = {
		WARRANTY: {
			WARRANTY: t('programTitleWarranty', 'Labour Warranty'),
			'10_400_WARRANTY': t(
				'programTitle10400Warranty',
				'10/400 Warranty',
			),
		},
		REBATES: {
			REBATES: t('programTitleRebates', 'Consumer Rebates'),
			COOP: t('programTitleCoop', 'Co-op'),
		},
		LOYALTY: {
			PRIVILEGE: t('programTitlePrivilege', 'Privilege'),
			PROMOTIONS: t('programTitlePromotions', 'Avalanche of Points'),
			PRESTIGE: t('programTitlePrestige', 'Prestige'),
		},
		CUSTOMER_SERVICE: {
			ROADSIDE: t('programTitleRoadside', 'Roadside'),
		},
		BUSINESS_DEVELOPMENT: {
			ON_TRACK: t('programTitleOnTrack', 'OnTrack'),
			BACKEND: t('programTitleBackend', 'Backend'),
		},
	}

	const PROGRAM_CATEGORIES = {
		WARRANTY: t('programCategoryTitleWarranty', 'Warranty'),
		REBATES: t('programCategoryTitleRebates', 'Rebates'),
		LOYALTY: t('programCategoryTitleLoyalty', 'Loyalty'),
		BUSINESS_DEVELOPMENT: t(
			'programCategoryTitleBusinessDevelopment',
			'Business Development',
		),
		CUSTOMER_SERVICE: t(
			'programCategoryTitleCustomerService',
			'Customer Service',
		),
	}

	let queryVars = {}

	queryVars.filter = {
		navisionId: {
			eq: user.navisionId,
		},
	}

	return (
		<div className="contentContainer">
			{/* { user.userType === 'STORE' && user.navisionId && 
                <Query query={ GET_STORE } 
                    variables={{ ...queryVars }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <Loader />
                        if (error) return `Error! ${error.message}`;

                        const { navisionId, storeNapaNumber, businessName, address, city, province } = data.store

                        return (
                            <span className='store-info'>
                                { navisionId } ({ storeNapaNumber })<br/>
                                { businessName }<br/>
                                { address }, { city } { province }
                            </span>
                        ) 
                    }}
                </Query>

            } */}

			<h1 className="membershipHeading">
				{t('napaProgramsHeading', 'My Programs')}
			</h1>

			<div>
				{queryData.me.scopes.length > 0 ? (
					Object.keys(PROGRAMS).map(categoryKey => {
						const category = PROGRAMS[categoryKey]
						const programCategoryKeys = []
						Object.keys(category).map(programKey => {
							return programCategoryKeys.push(programKey)
						})
						let hasCategoryMembership = programCategoryKeys.some(
							programCategoryKey =>
								queryData.me.scopes.indexOf(
									programCategoryKey,
								) !== -1,
						)
						if (hasCategoryMembership) {
							return (
								<div>
									<div className="membershipHeadingCategory">
										<div className="membershipHeadingTitle">
											{PROGRAM_CATEGORIES[categoryKey]}
										</div>
									</div>
									<div className="membershipGrid">
										{queryData.me.scopes.map((scope, i) => {
											if (
												!programCategoryKeys.includes(
													scope,
												)
											)
												return null
											if (user.userType === 'EMPLOYEE') {
												// console.log(
												// 	queryData.me?.userPermissionGrants[0].scopes,
												// )
												if (
													!queryData.me?.userPermissionGrants[0].scopes.includes(
														scope,
													)
												)
													return null
											}
											return (
												<button
													key={i}
													type="button"
													className="membership__button"
													onClick={async () => {
														const { data } =
															await oauthGenerate(
																{
																	variables: {
																		client: scope,
																	},
																},
															)
														const {
															oauthToken,
															oauthRedirect,
														} = data.oauthGenerate
														const uri = `${oauthRedirect}/oauth/${i18n.language}/${oauthToken}`

														window.location.replace(
															uri,
														)
														// window.open(uri)
													}}
												>
													<div className="program">
														<div>
															{
																PROGRAMS[
																	categoryKey
																][scope]
															}
														</div>
													</div>
													<div className="viewAccount">
														{t(
															'napaProgramsViewAccountLink',
															'View Account',
														)}
													</div>
												</button>
											)
										})}
									</div>
								</div>
							)
						}
						return null
					})
				) : (
					<NoData>
						<p>
							{t(
								'membershipNoAccess',
								'No programs were found for your account. Please speak with your supplying NAPA Store.',
							)}
						</p>
					</NoData>
				)}
			</div>

			{user.userType === 'STORE' && <StoreInfo />}
			{user.userType === 'INSTALLER' && <InstallerInfo />}
		</div>
	)
}

function StoreInfo() {
	const { user } = useAuthentication()

	const queryVars = {
		filter: {
			navisionId: {
				eq: user.navisionId,
			},
		},
	}

	const { error, loading, data } = useQuery(GET_STORE, {
		variables: queryVars,
	})

	if (error) return 'Error loading store info'
	if (loading) return <Loader />

	const { store } = data

	if (!store) return null

	return (
		<div className="storeWrapper">
			<div className="storeContainer">
				<div className="storeNumber">
					{store.navisionId} ({store.napaNumber})
				</div>
				<div className="storeBusinessName">{store.businessName}</div>
				<div className="storeAddress">
					{store.address}, {store.city}, {store.province}{' '}
					{store.postal}
				</div>
			</div>
		</div>
	)
}

function InstallerInfo() {
	const { user } = useAuthentication()

	const queryVars = {
		filter: {
			navisionId: {
				eq: user.navisionId,
			},
		},
	}

	const { error, loading, data } = useQuery(GET_INSTALLER, {
		variables: queryVars,
	})

	if (error) return 'Error loading store info'
	if (loading) return <Loader />

	const { installer } = data

	if (!installer) return null

	return (
		<div className="storeWrapper">
			<div className="storeContainer">
				<div className="storeNumber">{installer.navisionId}</div>
				<div className="storeBusinessName">
					{installer.businessName}
				</div>
				<div className="storeAddress">
					{installer.address}, {installer.city}, {installer.province}{' '}
					{installer.postal}
				</div>
			</div>
		</div>
	)
}
