import gql from 'graphql-tag'

export const GET_STORE = gql`
    query Store($filter: StoreFilter) {
        store(filter: $filter) {
            id
            navisionId
            businessName
            address
            city
            province
            storeNapaNumber
            napaNumber
        }
    }
`