import React from 'react'
import './InputWrapper.scss'
import { Icon } from '@iconify/react'

export default function InputWrapper({
	children,
	isASelect,
	isFocused,
	isFilled,
	inputId,
	label,
	type,
	onEyeballClick,
	eyeballVisibility,
}) {
	return (
		<label htmlFor={inputId}>
			<div
				className={`input-container inputFlex ${
					isASelect ? 'select' : ''
				}  ${isFilled ? 'input-filled' : ''} ${
					isFocused ? 'input-focus' : ''
				}`}
			>
				<div>
					<span className="label">{label}</span>

					{children}
				</div>
				{type === 'password' && (
					<button
						type="button"
						className="eyeball"
						onClick={() => onEyeballClick()}
					>
						<Icon
							icon={
								eyeballVisibility
									? 'mdi:eye-lock-open'
									: 'mdi:eye-lock'
							}
							fontSize={24}
						/>
					</button>
				)}

				{/* NOTE: Error checking to be implemented later */}
				{/* <div className="input-error">
                    <span></span>
                </div> */}
			</div>
		</label>
	)
}
