import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
// import { offsetLimitPagination } from '@apollo/client/utilities'

import { COMPANIES } from 'hooks/company'

const httpLink = createHttpLink({
	uri:
		process.env.NODE_ENV === 'development'
			? 'http://localhost:4000/graphql'
			: 'https://napaapi.com/graphql',
})

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('accessToken')

	const company =
		process.env.NODE_ENV === 'production'
			? COMPANIES[window.location.hostname]
			: process.env.REACT_APP_COMPANY

	return {
		headers: {
			...headers,
			client: 'PROGRAMS',
			company,
			authorization: token ? `Bearer ${token}` : '',
		},
	}
})

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	name: `NAPA - Programs (${process.env.NODE_ENV})`,
	version: '1.0.0',
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {},
			},
		},
	}),
	onError: ({ networkError }) => {
		if (networkError) {
			if (networkError.statusCode === 401) {
				localStorage.removeItem('accessToken')
				const refreshToken = localStorage.getItem('refreshToken')

				if (!refreshToken) {
					client.resetStore()
					localStorage.removeItem('accessToken')
					localStorage.removeItem('refreshToken')
				}
			}
		}
	},
})

export default client
