import gql from 'graphql-tag'

export const OAUTH_GENERATE = gql`
	mutation OauthGenerate($client: String) {
		oauthGenerate(client: $client) {
			oauthToken
			oauthRedirect
		}
	}
`

export const OAUTH_AUTHENTICATE = gql`
	query OauthAuthenticate($payload: OauthPayload!) {
		oauthAuthenticate(payload: $payload) {
			accessToken
			refreshToken
		}
	}
`

export const ACTIVATE_CHECK = gql`
	query ActivateCheck($payload: ActivateCheckPayload!) {
		activateCheck(payload: $payload) {
			accessToken
			refreshToken
			oauthToken
			oauthRedirect
		}
	}
`

export const AUTHENTICATE = gql`
	mutation Authenticate($payload: AuthPayload!) {
		authenticate(payload: $payload) {
			accessToken
			refreshToken
			oauthToken
			oauthRedirect
		}
	}
`

export const REFRESH_TOKEN = gql`
	mutation RefreshToken($payload: RefreshTokenPayload!) {
		refreshToken(payload: $payload) {
			accessToken
		}
	}
`

export const ME = gql`
	query Me {
		me {
			uuid
			username
			userPermissionGrants {
				id
				uuid
				username
				scopes
			}
			scopes
		}
	}
`

export const REMOVE_SALES_REP_PERMISSIONS = gql`
	mutation RemoveSalesRepPermissions(
		$payload: RemoveSalesRepPermissionsPayload!
	) {
		removeSalesRepPermissions(payload: $payload) {
			username
		}
	}
`

export const LINK_SALES_REP_ACCOUNT = gql`
	mutation LinkSalesRepAccount($payload: LinkSalesRepAccountPayload!) {
		linkSalesRepAccount(payload: $payload) {
			username
		}
	}
`

export const ACTIVATE_ACCOUNT = gql`
	mutation ActivateAccount($payload: ActivateAccountPayload!) {
		activateAccount(payload: $payload) {
			accessToken
			refreshToken
			oauthToken
			oauthRedirect
		}
	}
`

export const LINK_EMPLOYEE_ACCOUNT = gql`
	mutation LinkEmployeeAccount($payload: LinkEmployeeAccountPayload!) {
		linkEmployeeAccount(payload: $payload) {
			id
			username
		}
	}
`

export const DELETE_EMPLOYEE_ACCOUNT = gql`
	mutation DeletedLinkedEmployeeAccount(
		$payload: LinkEmployeeAccountPayload!
	) {
		deleteLinkedEmployeeAccount(payload: $payload) {
			id
			username
		}
	}
`

export const FORGOT_PASSWORD = gql`
	mutation ForgotPassword($payload: ForgotPasswordPayload!) {
		forgotPassword(payload: $payload)
	}
`
