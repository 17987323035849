import React, { createContext, useContext } from 'react'
import { Helmet } from 'react-helmet'

const CompanyContext = createContext()

export const COMPANIES = {
	'mynapaprograms.com': 'NAPA',
	'myaagprograms.com': 'AAG',
}

const HEAD = {
	NAPA: {
		title: 'My NAPA Programs',
	},
	AAG: {
		title: 'My AAG Programs',
	},
}

const companyOverride = 'NAPA'

export default function CompanyProvider({ children }) {
	const company =
		process.env.NODE_ENV === 'production'
			? COMPANIES[window.location.hostname]
			: companyOverride
			? companyOverride
			: process.env.REACT_APP_COMPANY

	return (
		<CompanyContext.Provider value={company}>
			<Helmet>
				<title>{HEAD[company].title}</title>
				<link
					rel="icon"
					type="image/png"
					href={
						company === 'AAG'
							? '/assets/favicon_aag.png'
							: '/assets/favicon_napa.png'
					}
				/>
			</Helmet>
			<div className={`companyContainer company--${company}`}>
				{children}
			</div>
		</CompanyContext.Provider>
	)
}

export function useCompany() {
	return useContext(CompanyContext)
}
