import React, { useState, useEffect } from 'react'
import { useQuery, useApolloClient, useMutation } from '@apollo/client'
import Form, { Input, Submit } from '../../components/Forms'
import { useAuthentication } from '../../hooks/useAuthentication'
import { ACTIVATE_ACCOUNT, ACTIVATE_CHECK } from '../../operations/Auth'
import { useTranslation } from 'react-i18next'
import owasp from 'owasp-password-strength-test'
import ErrorMessage from '../../components/ErrorMessage'
import Loader from '../../components/Loader'

export default function Activate({ match, history, location }) {
	const { i18n } = useTranslation()
	const client = useApolloClient()
	const { setAuthenticated } = useAuthentication()

	useEffect(() => {
		// DESTROY ANY PREVIOUS SESSIONS
		setAuthenticated(false)
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
		client.resetStore()
		// eslint-disable-next-line
	}, [])

	const { language, program, token } = match.params

	if (i18n.language !== match.params.language) i18n.changeLanguage(language)

	const {
		error: activateCheckError,
		loading: activateCheckLoading,
		data: activateCheckData,
	} = useQuery(ACTIVATE_CHECK, {
		variables: {
			payload: {
				token: token,
				client: program,
			},
		},
	})

	if (activateCheckLoading) return <Loader />
	if (activateCheckError) return 'Error'

	const { accessToken, refreshToken, oauthToken, oauthRedirect } =
		activateCheckData.activateCheck

	if (accessToken) {
		localStorage.setItem('accessToken', accessToken)
		localStorage.setItem('refreshToken', refreshToken)
		setAuthenticated(true)

		if (program && program !== 'PROGRAMS') {
			const uri = `${oauthRedirect}/oauth/${i18n.language}/${oauthToken}`
			window.location.replace(uri)
			return null
		}

		history.replace('/')
		history.push('/')
		return null
	}

	return <ActivationForm match={match} history={history} />
}

function ActivationForm({ match, history }) {
	const { t, i18n } = useTranslation()
	const { setAuthenticated } = useAuthentication()

	const [activate] = useMutation(ACTIVATE_ACCOUNT)

	const [inputError, setInputError] = useState()
	const [passwordTestsPassed, setPasswordTestsPassed] = useState([])
	const [passwordTestsFailed, setPasswordTestsFailed] = useState([])
	const [isPassphrase, setPassphrase] = useState(false)

	const { language, program, token } = match.params

	const ERROR_MESSAGES = {
		UNAUTHORIZED: t(
			'activateErrorUnauthorized',
			'Sorry, but your token is invalid.',
		),
		PASSWORD_REQUIREMENTS_FAILED: t(
			'activateErrorPasswordRequirementsFailed',
			'Sorry, your password does not meet strength requirements.',
		),
		PASSWORDS_DO_NOT_MATCH: t(
			'activateErrorPasswordsDoNotMatch',
			'Your passwords do not match.',
		),
	}

	const passwordTests = [
		t(
			'passwordTestZero',
			'The password must be at least 10 characters long.',
		),
		t('passwordTestOne', 'The password must be fewer than 128 characters.'),
		t(
			'passwordTestTwo',
			'The password may not contain sequences of three or more repeated characters.',
		),
		t(
			'passwordTestThree',
			'The password must contain at least one lowercase letter.',
		),
		t(
			'passwordTestFour',
			'The password must contain at least one uppercase letter.',
		),
		t('passwordTestFive', 'The password must contain at least one number.'),
		t(
			'passwordTestSix',
			'The password must contain at least one special character.',
		),
	]

	const isPasswordReset = match.path.indexOf('/r/') !== -1 ? true : false

	return (
		<Form
			onSubmit={async ({
				inputs: { password, passwordConfirm },
				enableSubmit,
			}) => {
				try {
					const { data } = await activate({
						variables: {
							payload: {
								language: language,
								activateToken: token,
								password,
								passwordConfirm,
								...(program && { client: program }),
							},
						},
					})

					const {
						accessToken,
						refreshToken,
						oauthToken,
						oauthRedirect,
					} = data.activateAccount

					localStorage.setItem('accessToken', accessToken)
					localStorage.setItem('refreshToken', refreshToken)
					setAuthenticated(true)

					if (program) {
						const uri = `${oauthRedirect}/oauth/${i18n.language}/${oauthToken}`
						window.location.replace(uri)
						return null
					}

					return history.replace('/')
					// history.push('/')
					// return null
				} catch (err) {
					const { graphQLErrors } = err
					enableSubmit()
					setInputError([
						'',
						ERROR_MESSAGES[graphQLErrors[0].message],
					])
				}
			}}
		>
			<div className="contentContainer contentContainer--login">
				<h1>
					{isPasswordReset
						? t('passwordResetTitle', `Let's reset your password!`)
						: t('activateTitle', `Let's activate your account!`)}
				</h1>

				{inputError && inputError[1] && (
					<ErrorMessage>{inputError[1]}</ErrorMessage>
				)}

				<input type="hidden" name="username" />
				<Input
					label={t('newPassword', 'New Password')}
					type="password"
					name="password"
					onChange={e => {
						const owaspTests = owasp.test(e.target.value)
						setPasswordTestsPassed(owaspTests.passedTests)
						setPasswordTestsFailed(owaspTests.failedTests)
						setPassphrase(owaspTests.isPassphrase)
					}}
				/>
				<Input
					label={t('confirmPassword', 'Confirm Password')}
					type="password"
					name="passwordConfirm"
				/>
				<Submit fullWidth>
					{isPasswordReset
						? t('passwordResetButton', 'Reset My Password')
						: t('activateMyAccount', 'Activate My Account')}
				</Submit>

				{isPassphrase ? (
					passwordTestsFailed[0] === 2 ? (
						<div className={`passwordTest passwordTest--failed`}>
							<i
								className={`material-icons`}
								style={{
									verticalAlign: 'bottom',
									color: 'red',
								}}
							>
								block
							</i>{' '}
							{passwordTests[2]}
						</div>
					) : (
						<div className="passwordTest">
							<i
								className={`material-icons`}
								style={{
									verticalAlign: 'bottom',
									color: 'green',
								}}
							>
								check_circle
							</i>{' '}
							{t('passwordTestPassphrase', 'Strong passphrase.')}
						</div>
					)
				) : (
					passwordTests.map((test, key) => {
						if ([1, 2].indexOf(key) !== -1) {
							if (passwordTestsFailed.indexOf(key) !== -1) {
								return (
									<div key={key} className="passwordTest">
										<i
											className={`material-icons`}
											style={{
												verticalAlign: 'bottom',
												color: 'red',
											}}
										>
											block
										</i>{' '}
										{test}
									</div>
								)
							}
							return null
						}
						return (
							<div
								key={key}
								className={`passwordTest ${
									passwordTestsPassed.indexOf(key) !== -1
										? 'passwordTest--passed'
										: 'passwordTest--failed'
								}`}
							>
								<i
									className={`material-icons`}
									style={{ verticalAlign: 'bottom' }}
								>
									check_circle
								</i>{' '}
								{test}
							</div>
						)
					})
				)}
			</div>
		</Form>
	)
}
