import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
// import { useGlobalState } from './hooks/useGlobalState'

function ScrollRestoration ({ location }) {

    // const { store, installer } = useGlobalState()

    useEffect(() => {
        window.scrollTo(0,0)
    },[location])
    return null
}

export default withRouter(ScrollRestoration)